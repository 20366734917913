import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import throttle from 'lodash/throttle';

import convertHexToRGB from '../../../web/src/utils/convertHexToRGB';
import getColorContrast from '../../../web/src/utils/getColorContrast';
import type { AnchorScrollTarget, BaseDocument, Highlight } from '../../types';
import type { HighlightResizeState } from '../../types/highlights';
import { LenientWindow } from '../../types/LenientWindow';
import { isHTMLElement } from '../../typeValidators';
import cloneDeep from '../../utils/cloneDeep';
import _convertHtmlToText from '../../utils/convertHtmlToText';
import delay from '../../utils/delay';
import {
  isDevOrTest,
  isDocumentShareApp,
  isExtension,
  isInReactNativeWebView,
  isIOSBrowser,
  os,
} from '../../utils/environment';
import makeLogger from '../../utils/makeLogger';
import { normalizeUrl } from '../../utils/urls';
import foregroundEventEmitter from '../eventEmitter';
// eslint-disable-next-line import/no-cycle
import { portalGate as portalGateToForeground } from '../portalGates/contentFrame/from/reactNativeWebview';
import type { HighlightElement } from '../types';
import type { ContentFrameSelectionInfo } from '../types/contentFramePuppeteerRelated';
import {
  EnlargementInProgressKnownHighlight,
  KnownHighlight,
  KnownHighlightsMap,
  KnownHighlightStatus,
  KnownHighlightThatFailedToDeserialize,
  KnownHighlightThatFailedToEnlarge,
  NonRenderedKnownHighlight,
  RemovalInProgressKnownHighlight,
  RenderedKnownHighlight,
  RenderInProgressKnownHighlight,
} from '../types/knownHighlights';
import type { RangyClassApplier, RangyRange } from '../types/rangy';
import classListSafe from '../utils/classListSafe';
import cleanUpHtmlForHighlighting from '../utils/cleanUpHtmlForHighlighting';
import closestWith from '../utils/closestWith';
import convertRangeToRangyRange from '../utils/convertRangeToRangyRange';
import cropRange from '../utils/cropRange';
import { defineCustomElement } from '../utils/customElements';
import { populateFocusableElements } from '../utils/findAllFocusableElements';
import findText from '../utils/findText';
import getClosestHTMLElement from '../utils/getClosestHTMLElement';
import getElementsBetween from '../utils/getElementsBetween';
import getHighlightElements from '../utils/getHighlightElements';
import getHighlightsInRange from '../utils/getHighlightsInRange';
import getHtmlFromRange from '../utils/getHtmlFromRange';
import getOppositeEnd from '../utils/getOppositeEnd';
import getRangeFromNodes from '../utils/getRangeFromNodes';
import getSiblings from '../utils/getSiblings';
import isElementInRange from '../utils/isElementInRange';
import isFocusableElement from '../utils/isFocusableElement';
import isHighlightNode from '../utils/isHighlightNode';
import isImage from '../utils/isImage';
import { deserializeRange, serializeRange } from '../utils/locationSerializer';
import rangy from '../utils/rangy';
import type { HighlightResult } from '../utils/Renderer';
import Renderer from '../utils/Renderer';
import SelectionEventsHandler from '../utils/SelectionEventsHandler';
import setSelectionBaseAndExtentIfDifferent from '../utils/setSelectionBaseAndExtentIfDifferent';
import tagsWhichCanBeHighlightedViaDoubleTap from '../utils/tagsWhichCanBeHighlightedViaDoubleTap';
import trimSelection, { grabPunctuationAtEnd, grabPunctuationAtStart } from '../utils/trimSelection';

const logger = makeLogger(__filename);

declare let window: LenientWindow;

/*
  What is progressive highlight rendering?

  TL;DR: We sometimes "virtualize" the insertion of highlight elements in the DOM in this file.

  For huge documents (e.g. books) with a lot of highlights, the highlighter in general can be very slow.
  Even just opening the document on a powerful machine can cause everything to freeze, block interactions,
  stop text from rendering on scroll, and so on. Plus it affects other interactions like creating new
  highlights.

  However, deserializing a highlight's serialized location string is not expensive. So when we a call to
  `addHighlight` happens, we don't immediately draw it. Instead, we store the details for later and also
  use IntersectionObserver to track when related "destination" elements are near the viewport, so we
  can render them in time.

  Spreading the workload out over time like this massively improves performance. Right now, we don't unrender
  highlight elements when you scroll them out of view. We'd see more de/serialization issues if we did it
  that often.

  Note: Anything that causes a scroll to a highlight (e.g. clicking a highlight in the notebook panel in the
  sidebar) needs to first trigger the highlight to render if it isn't already.

  Progressive highlight rendering is only used if the document's word count is over a certain threshold. It's
  also never used in the extension because we've no idea what the document is like, if it's overflowing
  horizontally instead of vertically, maybe there are multiple scrolling ancestors, and so on.

  Make sure to check out reading-clients/reader/shared/types/knownHighlights.ts.
*/
let shouldProgressivelyRender = false;

let knownHighlights: KnownHighlightsMap = {};
const docIdToIdsOfHighlightThatMustExistMap: {
  [docId: string]: Highlight['id'][];
} = {};
let docId: BaseDocument['id'] | undefined;
let documentUrl: string | null = null;
let hasScrolledToHighlight = false;
const highlightElementsSelector = Renderer.getHighlightElementSelector();
let highlightIdToScrollTo: Highlight['id'] | null;

const initialHighlightResizeState: HighlightResizeState = {
  edgeResizeStartedFrom: null,
  highlightId: null,
  status: 'inactive',
};

/*
  This is controlled from outside (by calling functions defined here). Don't update it inside the content
  frame.
*/
let highlightResizeState: HighlightResizeState = initialHighlightResizeState;
let isInitialized = false;
let lastRightClickedImage: HTMLImageElement | null = null;
let lastRightClickedSelectionInfo: ContentFrameSelectionInfo | null | undefined;
let lastSeenHighlightElements: HighlightElement[] = [];
let mutationObserver: MutationObserver | null = null;
let resizeObserver: ResizeObserver | null = null;
let renderer: Renderer | undefined;
let selectionEventsHandler: SelectionEventsHandler | null = null;
let initialSelectionDetailsOfHighlightBeingResized: Pick<Selection, 'focusNode' | 'focusOffset'> | null =
  null;

// An array of elements from the document text content that may be focused on, like paragraphs, anchors etc
let focusableElements: Element[] = [];

/* Internal functions */

const addHighlight = async (
  { content, html, id, location }: Highlight,
  shouldRenderImmediately?: boolean,
): Promise<void> => {
  try {
    if (!isInitialized) {
      throw new Error('Not initialized');
    }

    if (!docId) {
      throw new Error('docId is not set');
    }

    if (knownHighlights[id]) {
      throw new Error('Highlight cannot be added, it already exists in knownHighlights');
    }

    const rendererThatExists = getRendererOrThrow();
    if (!rendererThatExists.classApplier) {
      throw new Error("Renderer's class applier is missing");
    }

    const newKnownHighlight: KnownHighlight = {
      content,
      html,
      id,
      intersectionObserver: null,
      location,
      renderedData: null,
      status: KnownHighlightStatus.NonRendered,
    } as NonRenderedKnownHighlight;

    const mustRenderImmediately =
      Boolean(shouldRenderImmediately) ||
      !shouldProgressivelyRender ||
      docIdToIdsOfHighlightThatMustExistMap[docId]?.includes(id);

    if (!mustRenderImmediately) {
      const onFindFailed = () => {
        setKnownHighlight({
          ...newKnownHighlight,
          status: KnownHighlightStatus.DeserializationFailed,
        } as KnownHighlightThatFailedToDeserialize);
        throw new Error('Failed to render');
      };

      let deserializationError: Error | undefined;
      let range: RangyRange | undefined;
      try {
        range = deserializeRange(
          location.split('|')[0], // only take first range if there are multiple
          rendererThatExists.containerNode,
          rendererThatExists.containerNode.ownerDocument,
          rendererThatExists.classApplier,
        );
      } catch (e) {
        deserializationError = e as Error;
      }

      // Handle error / no result
      if (!range) {
        const warn = window.location.pathname.startsWith('/reader/shared/')
          ? // eslint-disable-next-line no-console
            console.warn
          : logger.warn.bind(logger);
        let logMessage = 'Highlight failed to deserialize';
        if (content) {
          logMessage += ', falling back to text search';
        } else {
          logMessage += ". Can't fall back to text search as no text given";
        }
        warn(logMessage, { error: deserializationError, newKnownHighlight });

        // No text, skip fallback text search and exit
        if (!content) {
          onFindFailed();
          return;
        }

        // Fall back to text search
        const findRange = await findText({
          containerNode: rendererThatExists.containerNode,
          logPrefix: `Highlight#${id}: `,
          text: content,
        });
        if (!findRange) {
          warn("Can't find text", { newKnownHighlight });
          onFindFailed();
          return;
        }
        range = findRange;
      }

      const onIntersect: IntersectionObserverCallback = async (entries, observer) => {
        if (!entries.some(({ isIntersecting }) => isIntersecting)) {
          return;
        }

        // None of the following should happen but I'd prefer to be safe and flag it in dev at least
        if (!isInitialized) {
          logger.warn(
            'IntersectionObserver fired even though content frame is not initialized. Disconnecting...',
          );
          observer.disconnect();
          return;
        }
        const knownHighlight = knownHighlights[id];
        if (!knownHighlight) {
          logger.warn('IntersectionObserver fired for unknown highlight. Disconnecting...', {
            id,
            knownHighlights,
          });
          observer.disconnect();
          return;
        }
        if (knownHighlight.status !== KnownHighlightStatus.NonRendered) {
          logger.warn(
            `IntersectionObserver fired for highlight with '${knownHighlight.status}' status. Disconnecting...`,
            {
              knownHighlight,
            },
          );
          removeIntersectionObserverFromKnownHighlight(knownHighlight.id);
          return;
        }

        if (entries.find((entry) => entry.isIntersecting)) {
          await renderHighlight({
            id,
          });
        }
      };

      const intersectionObserverOptions: IntersectionObserverInit = {
        // `root` is the viewport
        rootMargin: '5000px 0px',
        threshold: 0,
      };
      const scrollAncestor = document.getElementById('document-reader-root');
      if (scrollAncestor) {
        intersectionObserverOptions.root = scrollAncestor;
      }
      const intersectionObserver = new IntersectionObserver(onIntersect, intersectionObserverOptions);

      let startElement: Element | undefined = getClosestHTMLElement(range.startContainer);
      if (!startElement) {
        throw new Error("Can't get start element from range");
      }
      if (startElement.isEqualNode(rendererThatExists.containerNode)) {
        const childNode = startElement.childNodes[range.startOffset];
        if (isHTMLElement(childNode)) {
          startElement = childNode;
        } else {
          const previousElementSiblings = getSiblings({
            direction: 'previous',
            element: childNode,
            matcher: isHTMLElement,
            shouldIncludeNonElements: true,
          });
          if (previousElementSiblings.siblings.length) {
            startElement = previousElementSiblings.siblings[0] as Element;
          } else {
            throw new Error("Can't get element from start text node");
          }
        }
      }

      let endElement: Element | undefined = getClosestHTMLElement(range.endContainer);
      if (!endElement) {
        throw new Error("Can't get end element from range");
      }
      if (endElement.isEqualNode(rendererThatExists.containerNode)) {
        const childNode =
          endElement.childNodes[range.endOffset] ?? endElement.childNodes[range.endOffset - 1];
        if (!childNode) {
          throw new Error("Can't get end child node from end element");
        }
        if (isHTMLElement(childNode)) {
          endElement = childNode;
        } else {
          const nextElementSiblings = getSiblings({
            direction: 'next',
            element: childNode,
            matcher: isHTMLElement,
            shouldIncludeNonElements: true,
          });
          if (nextElementSiblings.siblings.length) {
            endElement = nextElementSiblings.siblings[0] as HTMLElement;
          } else {
            throw new Error("Can't get element from end text node");
          }
        }
      }

      const elements: Element[] = [startElement];

      if (!endElement.isEqualNode(startElement)) {
        const container = getClosestHTMLElement(range.commonAncestorContainer);
        if (!container) {
          throw new Error("Can't get closest HTML element from range common ancestor");
        }
        elements.push(
          ...getElementsBetween({
            container,
            end: endElement,
            start: startElement,
          }),
          endElement,
        );
      }

      for (const element of elements) {
        intersectionObserver.observe(element);
      }

      // eslint-disable-next-line require-atomic-updates
      newKnownHighlight.intersectionObserver = intersectionObserver;
    }

    setKnownHighlight(newKnownHighlight);

    if (mustRenderImmediately) {
      await renderHighlight({
        id,
      });
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Failed to add highlight`, e);
    throw e;
  }
};

const addHighlightIdsToDocIdToIdsOfHighlightThatMustExistMap = (
  docId: BaseDocument['id'],
  highlightIds: Highlight['id'][],
) => {
  if (!highlightIds) {
    return;
  }

  if (!docIdToIdsOfHighlightThatMustExistMap[docId]) {
    docIdToIdsOfHighlightThatMustExistMap[docId] = [];
  }

  for (const highlightId of highlightIds) {
    if (!docIdToIdsOfHighlightThatMustExistMap[docId].includes(highlightId)) {
      docIdToIdsOfHighlightThatMustExistMap[docId].push(highlightId);
    }
  }
};

const renderHighlight = async ({
  id,
}: {
  id: Highlight['id'];
}) => {
  try {
    if (!isInitialized) {
      throw new Error('Not initialized');
    }

    if (!knownHighlights[id]) {
      throw new Error('Unknown highlight ID');
    }

    if (
      ![KnownHighlightStatus.EnlargementInProgress, KnownHighlightStatus.NonRendered].includes(
        knownHighlights[id].status,
      )
    ) {
      logger.warn(
        `renderHighlight exiting; the highlight status is '${knownHighlights[id].status}'`,
        knownHighlights[id],
      );
      return;
    }

    setKnownHighlight({
      ...knownHighlights[id],
      status: KnownHighlightStatus.RenderInProgress,
    } as RenderInProgressKnownHighlight);
    removeIntersectionObserverFromKnownHighlight(id);

    const rendererThatExists = getRendererOrThrow();
    let highlightResult: HighlightResult | undefined;

    try {
      highlightResult = await rendererThatExists.highlightLocation(
        id,
        knownHighlights[id].location,
        knownHighlights[id].content,
      );
    } catch (e) {
      let logMessage = 'Failed to highlight by location';
      if (knownHighlights[id].content) {
        logMessage += ', falling back to text search';
      } else {
        logMessage += ". Can't fall back to text search as no text given";
      }
      logger.debug(logMessage, {
        error: e,
        location: knownHighlights[id].location,
        text: knownHighlights[id].content,
      });

      if (knownHighlights[id].content) {
        highlightResult = await rendererThatExists.highlightText(
          id,
          knownHighlights[id].content as string,
        );
        if (!highlightResult) {
          return;
        }
      } else {
        throw e;
      }
    }

    if (!highlightResult.elements.length) {
      logger.warn('No elements highlighted, may already be highlighted');
    }

    const renderedData: RenderedKnownHighlight['renderedData'] = {
      content: highlightResult.rangeText,
      html: highlightResult.html,
      rangyHighlight: highlightResult.rangyHighlight,
    };

    if (
      isDevOrTest &&
      (renderedData.content?.trim() !== knownHighlights[id].content?.trim() ||
        cleanUpHtmlForHighlighting(renderedData.html) !==
          cleanUpHtmlForHighlighting(knownHighlights[id].html))
    ) {
      logger.warn('New rendered highlight data differs from saved / expected data', {
        knownHighlight: knownHighlights[id],
        renderedData,
      });
    }

    setKnownHighlight({
      ...knownHighlights[id],
      id,
      renderedData,
      status: KnownHighlightStatus.Rendered,
    } as RenderedKnownHighlight);

    for (const highlightElement of highlightResult.elements) {
      if (isImage(highlightElement)) {
        highlightElement.addEventListener('click', onClickImageHighlight);
      }
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Failed to render highlight', e);
    throw e;
  }
};

const destroyObservers = () => {
  mutationObserver?.disconnect();
  mutationObserver = null;
  resizeObserver?.disconnect();
  resizeObserver = null;
};

const getActiveClassName = (highlightElement: HighlightElement): string => {
  const baseClassName = isImage(highlightElement)
    ? Renderer.imageHighlightClassName
    : Renderer.textHighlightTagName;
  return `${baseClassName}--active`;
};

let defaultHighlightRgb: number[] | undefined;
const getDefaultHighlightRgb = () => {
  if (!defaultHighlightRgb) {
    defaultHighlightRgb = convertHexToRGB(
      window.getComputedStyle(document.documentElement).getPropertyValue('--js_highlight-normal').trim(),
    );
  }
  return defaultHighlightRgb;
};

const getHighlightElementsForId = (highlightId?: Highlight['id']): HighlightElement[] => {
  if (!isInitialized || !highlightId) {
    return [];
  }
  return getHighlightElements({
    container: getRendererOrThrow().containerNode,
    id: highlightId,
  });
};

const getHighlightElementsInDom = (container?: HTMLElement): HighlightElement[] => {
  if (!container) {
    return [];
  }

  return getHighlightElements({ container });
};

const getDoc = () => getRendererOrThrow().containerNode.ownerDocument;

const getHighlightIdsInRange = (range?: Range): Highlight['id'][] => {
  if (!range || range.collapsed) {
    return [];
  }

  return getHighlightsInRange(
    Object.keys(knownHighlights).map((id) => ({
      id,
      elements: getHighlightElementsForId(id),
    })),
    range,
  ).map(({ id }) => id);
};

const getHighlightIdsInRangeDescribedBySerializedLocation = async (
  location: string,
): Promise<Highlight['id'][]> => {
  if (!renderer) {
    throw new Error('Renderer is missing');
  }
  if (!renderer.classApplier) {
    throw new Error("Renderer's class applier is missing");
  }

  const range = deserializeRange(
    location.split('|')[0], // only take first range if there are multiple
    renderer.containerNode,
    renderer.containerNode.ownerDocument,
    renderer.classApplier,
  );

  if (!range || range.collapsed) {
    return [];
  }

  return getHighlightIdsInRange(range);
};

function getRendererOrThrow(): Renderer {
  if (!renderer) {
    throw new Error('Renderer does not exist');
  }
  return renderer;
}

const getSel = (): ReturnType<typeof rangy.getSelection> => rangy.getSelection(getWindow());

const getSelectionInfoFromRange = ({
  range,
  shouldExpandToHighlightBounds,
}: {
  range: Range;
  shouldExpandToHighlightBounds?: boolean;
}): ContentFrameSelectionInfo => {
  const rangyRange = convertRangeToRangyRange(range);
  const rendererThatExists = getRendererOrThrow();

  let expandedRange = rangyRange;

  if (shouldExpandToHighlightBounds) {
    const highlightIdsInRange = getHighlightIdsInRange(rangyRange);
    const highlightElementsInRange = [];

    for (const highlightId of highlightIdsInRange) {
      const elementsInRange = getHighlightElementsForId(highlightId).filter((element) =>
        isElementInRange(element, rangyRange),
      );
      if (elementsInRange.length) {
        highlightElementsInRange.push(...elementsInRange);
      }
    }

    expandedRange = highlightElementsInRange.length
      ? rangyRange.union(getRangeFromNodes(highlightElementsInRange))
      : rangyRange;
  }

  const html = cleanUpHtmlForHighlighting(getHtmlFromRange(expandedRange));

  const result = {
    html,
    location: serializeRange({
      classApplier: rendererThatExists.classApplier as RangyClassApplier,
      containerNode: rendererThatExists.containerNode,
      range: expandedRange,
    }),
    markdown: convertHtmlToText(html, documentUrl || ''),
    offset: expandedRange.getBookmark().start,
    text: expandedRange.toString().replace(/\u2060/g, ''), // Remove Word-Joiner
  };

  return result;
};

const getWindow = () => getDoc().defaultView as Window;

// Check if contrast versus background colours is sufficient
const hasEnoughColorContrast = (element: HTMLElement) => {
  if (!isExtension) {
    return true;
  }
  const getRgb = (node: Node) => {
    if (!isHTMLElement(node)) {
      return;
    }

    const backgroundColor = window.getComputedStyle(node).getPropertyValue('background-color').trim();
    let rgb;

    if (backgroundColor.startsWith('#')) {
      rgb = convertHexToRGB(backgroundColor);
    } else if (backgroundColor.startsWith('rgb')) {
      const matches = backgroundColor.match(/rgba?\((\d+),\s?(\d+),\s?(\d+)(,\s?(\d+))?\)/i);

      if (matches) {
        const rgba = [...matches.slice(1, 4), matches[5]].map((input) =>
          input === undefined ? undefined : parseInt(input, 10),
        );
        if (!(rgba.length > 3 && rgba[3] === 0)) {
          // Ignore if transparent
          rgb = rgba.slice(0, 3); // Discard transparency
        }
      }
    }

    return rgb;
  };

  const ancestors = [document.body, document.documentElement];
  const closestElementWithBackground = closestWith<HTMLElement>(element.parentNode, (node) =>
    Boolean(getRgb(node)),
  );
  if (closestElementWithBackground && !ancestors.includes(closestElementWithBackground)) {
    ancestors.push(closestElementWithBackground);
  }

  return ancestors.every((ancestor) => {
    const rgb = getRgb(ancestor);
    if (!rgb) {
      return true;
    }

    const contrast = getColorContrast(getDefaultHighlightRgb(), rgb);

    // The default colour vs a white background is 1.07
    return (
      contrast >= 1 &&
      // Make sure it isn't too yellow. #FFF105 would pass otherwise
      rgb[0] &&
      rgb[1] &&
      rgb[2] &&
      !(rgb[0] > 150 && rgb[1] > 150 && rgb[2] < 50)
    );
  });
};

const initializeObservers = ({
  containerNode,
}: {
  containerNode: HTMLElement;
}) => {
  /*
    Run it once in case there are existing highlights to be safe.
    This handles when highlightIdToOpenAt changes.
  */
  processHighlights({ contentContainer: containerNode });

  // Listen for new highlight elements
  mutationObserver = new MutationObserver(async (mutationsList) => {
    if (!isInitialized) {
      logger.warn(
        'contentFrame MutationObserver fired when content frame is not initialized. Disconnecting...',
      );
      mutationObserver?.disconnect();
      mutationObserver = null;
      return;
    }

    if (
      !mutationsList.some(
        ({ target }) =>
          isHTMLElement(target) &&
          (target.isEqualNode(containerNode) ||
            isHighlightNode(target) ||
            target.matches(highlightElementsSelector)),
      )
    ) {
      return;
    }

    // When deleting an image highlight in the extenion, we need a little timeout
    if (
      isExtension &&
      mutationsList.length === 1 &&
      mutationsList[0].attributeName === 'class' &&
      isImage(mutationsList[0].target)
    ) {
      setTimeout(() => {
        if (!mutationObserver || !isInitialized) {
          return;
        }
        processHighlights({ contentContainer: containerNode });
      }, 10);
      return;
    }

    processHighlights({ contentContainer: containerNode });
  });

  mutationObserver.observe(containerNode, {
    attributes: true,
    attributeFilter: ['class'],
    childList: true,
    subtree: true,
  });

  // Emit an event when images load
  resizeObserver = new ResizeObserver(() => {
    portalGateToForeground.emit('content-moved');
  });
  for (const img of containerNode.querySelectorAll('img')) {
    resizeObserver.observe(img);
  }
};

const onClickImageHighlight: EventListenerOrEventListenerObject = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

const onContextMenuOpened = async (event: MouseEvent) => {
  if (isInReactNativeWebView) {
    const isAndroid = os.name.toLowerCase().includes('android');
    if (window.isAutoHighlightingEnabled || !isAndroid) {
      event.preventDefault();
    }
    if (isAndroid) {
      const isHighlightableSelectionResult = renderer?.isHighlightableSelection({
        highlightResizeState,
      });
      // eslint-disable-next-line newline-per-chained-call
      if (isHighlightableSelectionResult?.isValid) {
        const selectionInfo = await getCurrentSelectionInfo({
          shouldExpandToHighlightBounds: true,
          shouldNotGrabPunctuation: false,
        });
        portalGateToForeground.emit('valid-selection-completed', { selectionInfo });
        portalGateToForeground.emit('valid-manual-selection-completed', {
          selectionInfo: selectionInfo ?? undefined,
        });
      } else if (isHighlightableSelectionResult?.reason === 'length') {
        const selectionInfo = await getCurrentSelectionInfo({
          shouldExpandToHighlightBounds: true,
          shouldNotGrabPunctuation: true,
        });
        portalGateToForeground.emit('valid-manual-selection-completed', {
          selectionInfo: selectionInfo ?? undefined,
        });
      }
    }
    return;
  }
  lastRightClickedImage = event.target instanceof HTMLImageElement ? event.target : null;
  lastRightClickedSelectionInfo = lastRightClickedImage ? null : await getCurrentSelectionInfo({});
};

const onHighlightElementClicked: EventListenerOrEventListenerObject = (event) => {
  const target = getClosestHTMLElement(event.target as Node);
  if (!target || target.closest('a')) {
    return;
  }
  event.stopPropagation();
  event.preventDefault();

  const highlightElement = target.closest<HighlightElement>(Renderer.getHighlightElementSelector());

  if (!highlightElement) {
    throw new Error("Can't find nearest highlight element from clicked element");
  }

  let iconClicked: 'note' | 'tag' | null = null;
  if (target.closest(`.${Renderer.highlightIconWrapperClassName}`)) {
    if (target.closest(`.${Renderer.highlightNoteIconClassName}`)) {
      iconClicked = 'note';
    } else if (target.closest(`.${Renderer.highlightTagIconClassName}`)) {
      iconClicked = 'tag';
    }
  }

  portalGateToForeground.emit('highlight-clicked', {
    highlightElementClassName: highlightElement.className,
    iconClicked,
    id: highlightElement.dataset.highlightId,
  });
};

function onHighlightElementMouseOut(event: Event) {
  const closestHighlightElement = getClosestHTMLElement(
    event.target as Node | null,
  )?.closest<HighlightElement>(Renderer.getHighlightElementSelector());

  if (!closestHighlightElement) {
    return;
  }

  for (const element of getHighlightElementsForId(closestHighlightElement.dataset.highlightId)) {
    element.classList.remove(Renderer.highlightHoverClassName);
  }
}

function onHighlightElementMouseOver(event: Event) {
  const closestHighlightElement = getClosestHTMLElement(
    event.target as Node | null,
  )?.closest<HighlightElement>(Renderer.getHighlightElementSelector());

  if (!closestHighlightElement) {
    return;
  }

  for (const element of getHighlightElementsForId(closestHighlightElement.dataset.highlightId)) {
    element.classList.add(Renderer.highlightHoverClassName);
  }
}

function onHighlightResizeHandleMouseDown(event: MouseEvent) {
  if (!event.target) {
    return;
  }

  const handleElement = (event.target as HTMLElement).closest<HighlightElement>(
    `.${Renderer.highlightResizeHandleClassName}`,
  );
  if (!handleElement) {
    return;
  }

  const highlightElement = handleElement.closest<HighlightElement>(
    Renderer.getHighlightElementSelector(),
  );

  if (!highlightElement?.dataset.highlightId) {
    return;
  }

  portalGateToForeground.emit('highlight-html-resize-handle-mousedown', {
    edge: handleElement.classList.contains(Renderer.highlightResizeHandleClassNameByEdge.start)
      ? 'start'
      : 'end',
    id: highlightElement.dataset.highlightId,
  });
}

const onHighlightElementDoubleClicked: EventListenerOrEventListenerObject = (event) => {
  event.stopPropagation();
  event.preventDefault();

  const target = event.target as HTMLElement;
  const highlightElement = target.closest<HighlightElement>(
    [Renderer.textHighlightTagName, `.${Renderer.imageHighlightClassName}`].join(', '),
  );

  if (!highlightElement) {
    throw new Error("Can't find nearest highlight element from clicked element");
  }

  let iconClicked: 'note' | 'tag' | null = null;
  if (target.closest(`.${Renderer.highlightIconWrapperClassName}`)) {
    if (target.closest(`.${Renderer.highlightNoteIconClassName}`)) {
      iconClicked = 'note';
    } else if (target.closest(`.${Renderer.highlightTagIconClassName}`)) {
      iconClicked = 'tag';
    }
  }

  portalGateToForeground.emit('highlight-double-clicked', {
    id: highlightElement.dataset.highlightId,
    iconClicked,
  });
};

function onPointerDown() {
  portalGateToForeground.emit('pointerdown');
}

function onPointerUp() {
  portalGateToForeground.emit('pointerup');
}

const prepareHighlightElementsForDeletion = (elements: HighlightElement[]): void => {
  /*
    Remove extra attributes for rangy
  */

  // eslint-disable-next-line no-restricted-syntax
  for (const element of elements) {
    element.removeEventListener('click', onHighlightElementClicked);
    element.removeEventListener('mouseover', onHighlightElementMouseOver);
    element.removeEventListener('mouseout', onHighlightElementMouseOut);

    // If there are any new attributes on the element, rangy will skip it, so we'll remove them and any extra classes
    if (element.tagName.toLowerCase() === 'rw-highlight') {
      Array.from(element.attributes).forEach(({ nodeName }) => {
        if (nodeName !== 'class') {
          element.removeAttribute(nodeName);
        }
      });
      // eslint-disable-next-line no-param-reassign
      element.className = Renderer.highlightClassName;

      element
        .querySelectorAll(Renderer.getHighlightCustomChildSelector())
        .forEach((descendant) => descendant.remove());
    } else {
      // Image highlight
      element.removeEventListener('click', onClickImageHighlight);
    }

    element.removeAttribute('data-highlight-id');
  }
};

const processHighlights = throttle(
  async ({
    contentContainer,
  }: {
    contentContainer: HTMLElement;
  }) => {
    if (!isInitialized) {
      return;
    }

    const highlightElements = getHighlightElementsInDom(contentContainer);
    for (const highlightElement of highlightElements) {
      highlightElement.addEventListener('click', onHighlightElementClicked);
      highlightElement.addEventListener('mouseover', onHighlightElementMouseOver);
      highlightElement.addEventListener('mouseout', onHighlightElementMouseOut);
      highlightElement
        .querySelectorAll<HTMLElement>(`.${Renderer.highlightResizeHandleClassName}`)
        .forEach((handleElement) =>
          handleElement.addEventListener('mousedown', onHighlightResizeHandleMouseDown),
        );
      highlightElement.ondblclick = onHighlightElementDoubleClicked;
      if (
        highlightIdToScrollTo &&
        highlightElement.dataset.highlightId === highlightIdToScrollTo &&
        !hasScrolledToHighlight
      ) {
        hasScrolledToHighlight = true;

        /*
        Why is there a delay? If there's a text fragment in the URL, the browser
        will scroll to that first.
        - We can't detect if there's a text fragment.
        - We can't prevent the browser from scrolling to the text fragment.
        - The browser could scroll to the wrong occurrence, so we scroll too.
        Worst case scenario: the browser scrolls to one location and then we
        scroll to another, there's a little visual jump.
        Side note: the default CSS highlighting for text fragments is unset.
      */
        await delay(10);

        if (!isInReactNativeWebView) {
          highlightElement.scrollIntoView({
            block: 'center', // vertical
            inline: 'center', // horizontal
          });
        } else {
          window.scrollingManager.scrollToElement(highlightElement);
        }
      }

      const alternativeColorClassName = `${Renderer.highlightClassName}--alternative-color`;
      if (
        /*
        This `.contains` shouldn't be needed right? No, `.add` always modifies the element,
        triggering any MutationObserver. So in this case we'd get an infinite loop
      */
        !highlightElement.classList.contains(alternativeColorClassName) &&
        !hasEnoughColorContrast(highlightElement)
      ) {
        classListSafe.add(highlightElement, alternativeColorClassName);
      }
    }

    if (!isEqual(highlightElements, lastSeenHighlightElements)) {
      lastSeenHighlightElements = highlightElements;
      portalGateToForeground.emit('highlight-elements-changed');
    }
  },
  50,
  {
    leading: true,
    trailing: true,
  },
);

const removeIntersectionObserverFromKnownHighlight = (id: Highlight['id']): void => {
  if (!knownHighlights[id]) {
    throw new Error('No highlight with that ID');
  }
  knownHighlights[id].intersectionObserver?.disconnect();
  knownHighlights[id].intersectionObserver = null;
};

const setKnownHighlight = (knownHighlight: KnownHighlight): void => {
  // Do some validation first
  for (const propertyPath of [
    'content',
    'html',
    'id',
    'location',
    'intersectionObserver',
    'renderedData',
    'status',
  ]) {
    if (typeof get(knownHighlight, propertyPath) === 'undefined') {
      throw new Error(`knownHighlight.${propertyPath} is undefined`);
    }
  }

  if (knownHighlight.status === KnownHighlightStatus.Rendered) {
    for (const propertyPath of ['renderedData.content', 'renderedData.html']) {
      const value = get(knownHighlight, propertyPath);
      if (typeof value === 'undefined') {
        throw new Error(
          `knownHighlight.${propertyPath} is undefined, even though status is '${KnownHighlightStatus.Rendered}'`,
        );
      }
      if (value === null) {
        throw new Error(
          `knownHighlight.${propertyPath} is null, even though status is '${KnownHighlightStatus.Rendered}'`,
        );
      }
    }
  }

  knownHighlights[knownHighlight.id] = knownHighlight;
};

/*
  Exported stuff below this point:
*/

export const activateHighlight = async (id: Highlight['id']): Promise<void> => {
  for (const highlightElement of getHighlightElementsForId(id)) {
    classListSafe.add(highlightElement, getActiveClassName(highlightElement));
  }
};

export const addHighlights = async (
  highlights: Highlight[],
  shouldRenderImmediately?: boolean,
): Promise<Highlight['id'][]> => {
  if (!isInitialized) {
    throw new Error('Not initialized');
  }

  const failedHighlightIds: Highlight['id'][] = [];
  for (const highlight of highlights) {
    // Note: this used to lookup by location only, not ID
    if (knownHighlights[highlight.id]) {
      logger.warn(`Not adding highlight#${highlight.id} as it's already in knownHighlights`);
      continue;
    }

    try {
      if (!isInitialized) {
        return [];
      }
      await addHighlight(highlight, shouldRenderImmediately);
    } catch (e) {
      failedHighlightIds.push(highlight.id);
      logger.error('Failed to add highlight', { highlight, e });
    }
  }

  return failedHighlightIds;
};

export const convertHtmlToText = _convertHtmlToText;

export const deactivateHighlight = async (id: Highlight['id']): Promise<void> => {
  for (const highlightElement of getHighlightElementsForId(id)) {
    classListSafe.remove(highlightElement, getActiveClassName(highlightElement));
  }
};

export const destroy = async (): Promise<void> => {
  if (!isInitialized) {
    logger.warn('destroy called when isInitialized is already false');
    return;
  }
  logger.debug('Destroy: start');

  try {
    const doc = getDoc();

    focusableElements = [];
    documentUrl = null;
    shouldProgressivelyRender = false;
    lastSeenHighlightElements = [];
    selectionEventsHandler?.destroy();
    selectionEventsHandler = null;
    initialSelectionDetailsOfHighlightBeingResized = null;

    await removeHighlights('all');

    renderer = undefined;

    doc.removeEventListener('contextmenu', onContextMenuOpened);
    doc.removeEventListener('pointerdown', onPointerDown);
    doc.removeEventListener('pointerup', onPointerUp);
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    doc.ondblclick = () => {};

    // This should be reset by the ContentFramePuppeteer but let's be safe
    setHighlightResizeState(initialHighlightResizeState);

    destroyObservers();
    // eslint-disable-next-line require-atomic-updates
    isInitialized = false;
    logger.debug('Destroy: end');
  } catch (error) {
    // eslint-disable-next-line require-atomic-updates
    isInitialized = false;
    logger.error('Destroy: error', { error });
    throw error;
  }
};

export const enlargeHighlight = async (highlight: Highlight): Promise<void> => {
  if (!knownHighlights[highlight.id]) {
    throw new Error('Unknown highlight ID');
  }
  if (knownHighlights[highlight.id].location === highlight.location) {
    logger.warn("Not enlarging highlight as it's already rendered at that location");
    return;
  }
  if (knownHighlights[highlight.id].status !== KnownHighlightStatus.Rendered) {
    if (knownHighlights[highlight.id].status === KnownHighlightStatus.EnlargementFailed) {
      logger.warn('enlargeHighlight exiting because this knownHighlight previouisly failed to enlarge');
      return;
    }
    throw new Error(`Highlight status is '${knownHighlights[highlight.id].status}'`);
  }

  setKnownHighlight({
    ...knownHighlights[highlight.id],
    content: highlight.content,
    html: highlight.html,
    location: highlight.location,
    status: KnownHighlightStatus.EnlargementInProgress,
  } as EnlargementInProgressKnownHighlight);

  try {
    await removeHighlights([highlight.id], false);
    await renderHighlight({ id: highlight.id });
  } catch (e) {
    setKnownHighlight({
      ...knownHighlights[highlight.id],
      status: KnownHighlightStatus.EnlargementFailed,
    } as KnownHighlightThatFailedToEnlarge);
    throw e;
  }
};

export const enlargeHighlights = async (highlights: Highlight[]): Promise<void> => {
  for (const highlight of highlights) {
    try {
      await enlargeHighlight(highlight);
    } catch (error) {
      logger.error('Failed to enlarge highlight', { error, highlight });
      continue;
    }
  }
};

export const getSelectionInfoFromElements = async ({
  elements,
  ...options
}: Parameters<typeof getCurrentSelectionInfo>[0] & { elements: HTMLElement[] }) => {
  const range = getRangeFromNodes(elements);

  if (!range || range.collapsed) {
    return;
  }

  return getSelectionInfoFromRange({
    range,
    ...options,
  });
};

export const getFocusableElementIndexForSelection = async () => {
  // Get surrounding paragraph serialization
  const rendererThatExists = renderer as Renderer;
  if (!rendererThatExists.containerNode) {
    return;
  }
  if (focusableElements.length === 0) {
    populateFocusableElements(rendererThatExists.containerNode, focusableElements);
  }

  const selection = getSel();
  if (!selection?.rangeCount) {
    return;
  }
  trimSelection({
    getHighlightResizeState: () => highlightResizeState,
    selection,
    shouldNotGrabPunctuation: true,
  });
  if (!selection?.rangeCount) {
    return;
  }

  const range: RangyRange | null = selection.getRangeAt(0);
  if (!range || range.collapsed) {
    return;
  }
  const closestElement = getClosestHTMLElement(selection.focusNode);

  let target: Element | HTMLElement | undefined = closestElement;
  if (
    closestElement &&
    !isFocusableElement(closestElement) &&
    !focusableElements.includes(closestElement)
  ) {
    // try to see if a parent node of this element is focusable
    target = closestWith(
      closestElement,
      (e: Node) =>
        focusableElements.includes(e as Element) &&
        (isFocusableElement(e) || e === rendererThatExists.containerNode),
    );
  }
  if (target === rendererThatExists.containerNode || !target) {
    // We went too far, lets just assume we have no target
    return;
  }

  return focusableElements.indexOf(target);
};

export const getCurrentSelectionInfo = async (
  options: Omit<Parameters<typeof getSelectionInfoFromRange>[0], 'range'> & {
    shouldNotGrabPunctuation?: boolean;
  },
): Promise<ContentFrameSelectionInfo | undefined> => {
  const selection = getSel();

  if (!selection?.rangeCount) {
    return;
  }

  trimSelection({
    getHighlightResizeState: () => highlightResizeState,
    selection,
    shouldNotGrabPunctuation: !isInReactNativeWebView || options.shouldNotGrabPunctuation,
  });

  if (!selection?.rangeCount) {
    return;
  }

  const range: RangyRange | null = selection.getRangeAt(0);
  if (!range || range.collapsed) {
    return;
  }

  const rendererThatExists = getRendererOrThrow();

  let expandedRange: RangyRange | null = range;

  if (options.shouldExpandToHighlightBounds) {
    for (const highlightId of getHighlightIdsInRange(range)) {
      expandedRange = expandedRange.union(getRangeFromNodes(getHighlightElementsForId(highlightId)));
    }
  }

  if (rendererThatExists.containerNode !== document.body) {
    expandedRange = cropRange(expandedRange, getRangeFromNodes([rendererThatExists.containerNode]));
  }

  if (!expandedRange || expandedRange.collapsed) {
    return;
  }

  return getSelectionInfoFromRange({
    range: expandedRange,
    ...options,
  });
};

export const getSelectionInfoFromSelector = async ({
  selector,
  ...options
}: Parameters<typeof getCurrentSelectionInfo>[0] & { selector: string }): ReturnType<
  typeof getCurrentSelectionInfo
> => {
  const rendererThatExists = getRendererOrThrow();
  const elements = Array.from(rendererThatExists.containerNode.querySelectorAll<HTMLElement>(selector));

  if (!elements.length) {
    return;
  }

  return getSelectionInfoFromElements({ elements, ...options });
};

export async function selectMatchingText(text: string) {
  const rendererThatExists = getRendererOrThrow();
  const range = await findText({
    text,
    containerNode: rendererThatExists.containerNode,
  });

  if (!range) {
    return;
  }

  const rangySelection = rangy.getSelection();
  rangySelection.setSingleRange(range);
}

export const getKnownHighlights = async (): Promise<typeof knownHighlights> => knownHighlights;

export const getHighlightElementsInSelection = async (): Promise<HighlightElement[]> => {
  const selection = getSel();
  if (!selection?.rangeCount) {
    return [];
  }
  return getHighlightsInRange(
    Object.keys(knownHighlights).map((id) => ({
      id,
      elements: getHighlightElementsForId(id),
    })),
    selection.getRangeAt(0),
  )
    .map(({ elements }) => elements)
    .flat();
};

export const getHighlightIdsInSelection = async ({
  serializedLocationToUseIfThereIsNoSelection,
}: {
  serializedLocationToUseIfThereIsNoSelection?: string;
}): Promise<Highlight['id'][]> => {
  const selection = getSel();
  const range = selection?.rangeCount ? selection.getRangeAt(0) : null;

  if (!range || range.collapsed) {
    if (serializedLocationToUseIfThereIsNoSelection) {
      return getHighlightIdsInRangeDescribedBySerializedLocation(
        serializedLocationToUseIfThereIsNoSelection,
      );
    }
    return [];
  }

  return getHighlightIdsInRange(range);
};

export const getHighlightIdsInSelector = async ({
  selector,
}: { selector: string }): Promise<Highlight['id'][]> => {
  const rendererThatExists = getRendererOrThrow();
  const elements = Array.from(rendererThatExists.containerNode.querySelectorAll(selector));

  if (!elements.length) {
    return [];
  }

  const range = getRangeFromNodes(elements);

  if (!range || range.collapsed) {
    return [];
  }

  return getHighlightIdsInRange(range);
};

export const getLastRightClickedImageSelectionInfo = async (): Promise<
  ContentFrameSelectionInfo | undefined
> => {
  if (!lastRightClickedImage) {
    return;
  }
  const range = rangy.createRange();
  range.selectNode(lastRightClickedImage);
  if (range.collapsed) {
    return;
  }
  return getSelectionInfoFromRange({
    range,
  });
};

export const getLastRightClickedSelectionInfo = async (): Promise<
  typeof lastRightClickedSelectionInfo
> => lastRightClickedSelectionInfo;

export const removeHighlights = async (
  highlightIds: Highlight['id'][] | 'all',
  shouldUpdateKnownHighlights = true,
): Promise<void> => {
  if (!isInitialized) {
    return;
  }

  const knownHighlightsToKeep: typeof knownHighlights = {};
  let knownHighlightsToRemove: typeof knownHighlights = {};
  if (highlightIds === 'all') {
    knownHighlightsToRemove = knownHighlights;
  } else {
    for (const knownHighlightsHighlight of Object.values(knownHighlights)) {
      if (highlightIds.includes(knownHighlightsHighlight.id)) {
        knownHighlightsToRemove[knownHighlightsHighlight.id] = knownHighlightsHighlight;
      } else {
        knownHighlightsToKeep[knownHighlightsHighlight.id] = knownHighlightsHighlight;
      }
    }
  }

  if (shouldUpdateKnownHighlights) {
    knownHighlights = knownHighlightsToKeep;
  }

  const rendererThatExists = getRendererOrThrow();
  let hasWarnedThatContainerNodeIsDetached = false;

  for (const highlightId of Object.keys(knownHighlightsToRemove)) {
    if (knownHighlights[highlightId]?.status === KnownHighlightStatus.RemovalInProgress) {
      logger.warn('Skipping highlight removal, already being removed', knownHighlights[highlightId]);
      continue;
    }

    knownHighlightsToRemove[highlightId] = {
      ...knownHighlightsToRemove[highlightId],
      status: KnownHighlightStatus.RemovalInProgress,
    } as RemovalInProgressKnownHighlight;

    knownHighlightsToRemove[highlightId].intersectionObserver?.disconnect();
    knownHighlightsToRemove[highlightId].intersectionObserver = null;

    const elementsToRemove = getHighlightElementsForId(highlightId);

    const isContainerNodeDetached = !document.body.contains(rendererThatExists.containerNode);
    if (isContainerNodeDetached && !hasWarnedThatContainerNodeIsDetached) {
      logger.warn('renderer containerNode is no longer alive');
      hasWarnedThatContainerNodeIsDetached = true;
    }

    /*
      If it's important that the rest runs even if there are no elements found. I.e. don't exit early.
      This is because the Renderer removes "highlights" from Rangy's internal list. The rest of the code,
      e.g. prepareHighlightElementsForDeletion, will safely run even if an empty array is passed.
    */
    if (
      !isContainerNodeDetached &&
      knownHighlightsToRemove[highlightId].renderedData &&
      !elementsToRemove.length
    ) {
      logger.warn(`removeHighlights: no elements found for highlight#${highlightId}`);
    }

    prepareHighlightElementsForDeletion(elementsToRemove);

    const textElementsRemoved = [];
    // eslint-disable-next-line no-async-promise-executor, no-loop-func
    await new Promise<void>(async (resolve): Promise<void> => {
      const textElementsToRemove = elementsToRemove.filter(
        (element) => !(element instanceof HTMLImageElement),
      );
      if (textElementsToRemove.length && !isContainerNodeDetached) {
        const mutationObserver = new MutationObserver((mutationsList) => {
          // eslint-disable-next-line no-restricted-syntax
          for (const mutation of mutationsList) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if (textElementsToRemove.includes(mutation.target as any)) {
              textElementsRemoved.push(mutation.target);
            }
          }
          if (textElementsRemoved.length >= textElementsToRemove.length) {
            mutationObserver.disconnect();
            resolve();
          }
        });
        mutationObserver.observe(rendererThatExists.containerNode, { childList: true, subtree: true });
      }

      if (knownHighlightsToRemove[highlightId].renderedData) {
        await rendererThatExists.removeHighlight({
          elements: elementsToRemove,
          rangyHighlight: (
            knownHighlightsToRemove[highlightId].renderedData as NonNullable<
              KnownHighlight['renderedData']
            >
          ).rangyHighlight,
        });
      }

      if (!textElementsToRemove.length || isContainerNodeDetached) {
        await delay(10);
        resolve();
      }
    });
  }

  // to be safe
  if (highlightIds === 'all' && rendererThatExists._rangyHighlighter) {
    rendererThatExists._rangyHighlighter.highlights = [];
  }
  foregroundEventEmitter.emit('content-frame:highlights-removed');
};

export const init = async ({
  containerNodeSelector = 'body',
  docId: docIdArgument,
  documentUrl: documentUrlArgument,
  querySelector = (selector: string) => document.querySelector(selector),
  shouldProgressivelyRender: shouldProgressivelyRenderUrlArgument,
}: {
  containerNodeSelector?: string;
  documentUrl?: string;
  docId?: BaseDocument['id'];
  // Only used by tests
  querySelector?: ParentNode['querySelector'];
  shouldProgressivelyRender: boolean;
}): Promise<void> => {
  const containerNode = querySelector<HTMLElement>(containerNodeSelector);
  documentUrl = documentUrlArgument ?? null;
  docId = docIdArgument;
  shouldProgressivelyRender = shouldProgressivelyRenderUrlArgument;

  if (docId) {
    /*
      Why prune it here in init rather than destroy? This is to work around the fact that the content frame
      could be destroyed & re-initialized during the lifetime of one document. So basically, we're only
      removing stale documents from this map once the document ID has changed (when init is called)
    */
    for (const key of Object.keys(docIdToIdsOfHighlightThatMustExistMap)) {
      if (key !== docId) {
        delete docIdToIdsOfHighlightThatMustExistMap[key];
      }
    }
  }

  if (!containerNode) {
    throw new Error('No containerNode');
  }

  if (isInitialized) {
    logger.warn(
      "Content frame init exiting, already initialized (maybe it didn't get destroyed properly?)",
    );
    return;
  }

  await defineCustomElement('rw-highlight', 'class ReadwiseHighlight extends ReadwiseElement {}', {
    extends: 'mark',
  });

  renderer = new Renderer({
    canHighlightStartInAnother: isInReactNativeWebView,
    containerNode,
  });

  const doc = containerNode.ownerDocument;

  focusableElements = [];
  populateFocusableElements(containerNode, focusableElements);

  const isHighlightableSelection: Renderer['isHighlightableSelection'] = (...args) => {
    if (!renderer) {
      logger.warn('renderer does not exist when expected');
      return { isValid: false, reason: 'no-renderer' };
    }

    return renderer.isHighlightableSelection(...args);
  };

  /*
    This function takes a selection and extends it to grab all the punctuation characters
    at the end of the selected text. (In IOS, typically the selection stops before punctuation)
    This only works on iOS (modifying selection on other platforms breaks active selection events)
   */
  const modifySelectionToGrabPunctuationOnIOS = () => {
    if (!isIOSBrowser()) {
      return;
    }
    const selection = getSel();
    if (!selection?.rangeCount) {
      return;
    }
    grabPunctuationAtStart(selection, () => highlightResizeState);
    grabPunctuationAtEnd(selection, () => highlightResizeState);
  };

  /*
    We select the whole highlight (all elements) when entering
    `native-selection-made-but-user-hasnt-started-resizing-yet` state. Then when the selection is
    updated, we make sure the anchor (node & offset) is kept the same (which is the opposite end of
    the highlight element(s) than the resize handle which is being used).
    This is good in general but there also sometimes in Safari the selection updates erratically.
  */
  function modifySelectionToMaintainHighlightElementEndAsAnchor(selection: Selection) {
    const edgeResizeStartedFrom = highlightResizeState.edgeResizeStartedFrom;
    if (!edgeResizeStartedFrom) {
      return;
    }

    const highlightElements = getHighlightElementsForId(highlightResizeState.highlightId);
    const oppositeEnd = getOppositeEnd(edgeResizeStartedFrom);
    const highlightElementAtOppositeEnd =
      highlightElements[oppositeEnd === 'start' ? 0 : highlightElements.length - 1];
    const highlightElementRange = getRangeFromNodes([highlightElementAtOppositeEnd]);

    setSelectionBaseAndExtentIfDifferent(
      [
        highlightElementRange[`${oppositeEnd}Container`],
        highlightElementRange[`${oppositeEnd}Offset`],
        (selection.focusNode ?? selection.anchorNode) as Node,
        selection.focusOffset,
      ],
      selection,
    );
  }

  selectionEventsHandler = new SelectionEventsHandler({
    onSelectionChange: async () => {
      const selection = window?.getSelection();
      const selectionText = selection?.toString() || '';
      portalGateToForeground.emit('selection-change', selectionText);

      if (selection) {
        if (highlightResizeState.status !== 'inactive') {
          modifySelectionToMaintainHighlightElementEndAsAnchor(selection);
        }

        try {
          modifySelectionToGrabPunctuationOnIOS();
        } catch (e) {
          // We failed to modify the selection, lets not crash and burn
          logger.warn('Failed to modify selection', { error: e });
        }

        if (
          highlightResizeState.status === 'native-selection-made-but-user-hasnt-started-resizing-yet' &&
          initialSelectionDetailsOfHighlightBeingResized &&
          !isEqual(
            initialSelectionDetailsOfHighlightBeingResized,
            pick(selection, ['focusNode', 'focusOffset']),
          )
        ) {
          portalGateToForeground.emit('selection-changed-while-waiting-for-resize-to-start');
        }
      }

      const isHighlightableSelectionResult = isHighlightableSelection({ highlightResizeState });
      if (isHighlightableSelectionResult.isValid) {
        return;
      }

      portalGateToForeground.emit('invalid-selection-completed');
      if (isHighlightableSelectionResult.reason !== 'length') {
        portalGateToForeground.emit('invalid-manual-selection-completed');
      }
    },
    onTextOrImageSelectedWithMouse: async (finalEvent: MouseEvent) => {
      const isHighlightableSelectionResult = isHighlightableSelection({ highlightResizeState });
      if (!isHighlightableSelectionResult.isValid) {
        portalGateToForeground.emit('invalid-selection-completed');

        if (isHighlightableSelectionResult.reason === 'length') {
          const selectionInfo = await getCurrentSelectionInfo({ shouldExpandToHighlightBounds: true });
          portalGateToForeground.emit('valid-manual-selection-completed', {
            isAltPressed: finalEvent.altKey,
            selectionInfo: selectionInfo ?? undefined,
          });
        } else {
          portalGateToForeground.emit('invalid-manual-selection-completed');
        }
      } else {
        const selectionInfo = await getCurrentSelectionInfo({ shouldExpandToHighlightBounds: true });
        portalGateToForeground.emit('valid-manual-selection-completed', {
          isAltPressed: finalEvent.altKey,
          selectionInfo: selectionInfo ?? undefined,
        });
        portalGateToForeground.emit('valid-selection-completed', {
          isAltPressed: finalEvent.altKey,
          selectionInfo,
        });
      }
    },

    /*
      This does not work in the Android web view (well, the underlying touchend event). It's the `contextmenu` event that
      causes a highlight to be created when auto-highlighting is enabled.
    */
    onTextOrImageSelectedWithTouch: async () => {
      const isHighlightableSelectionResult = isHighlightableSelection({ highlightResizeState });
      if (!isHighlightableSelectionResult.isValid) {
        portalGateToForeground.emit('invalid-selection-completed');

        if (isHighlightableSelectionResult.reason === 'length') {
          const selectionInfo = await getCurrentSelectionInfo({ shouldExpandToHighlightBounds: true });
          portalGateToForeground.emit('valid-manual-selection-completed', {
            selectionInfo: selectionInfo ?? undefined,
          });
        } else {
          portalGateToForeground.emit('invalid-manual-selection-completed');
        }
      } else {
        const selectionInfo = await getCurrentSelectionInfo({ shouldExpandToHighlightBounds: true });
        portalGateToForeground.emit('valid-manual-selection-completed', {
          selectionInfo: selectionInfo ?? undefined,
        });
        portalGateToForeground.emit('valid-selection-completed', { selectionInfo });
      }
    },
  });

  // If already initialized after promise is resolved
  if (isInitialized) {
    return;
  }
  doc.addEventListener('contextmenu', onContextMenuOpened);
  doc.addEventListener('pointerdown', onPointerDown);
  doc.addEventListener('pointerup', onPointerUp);
  doc.ondblclick = async (event) => {
    if (!isInReactNativeWebView || !event.target || !isHTMLElement(event.target as Node)) {
      return;
    }
    const element = getClosestHTMLElement(event.target as Node)?.closest<HTMLElement>(
      tagsWhichCanBeHighlightedViaDoubleTap.join(','),
    );
    if (!element) {
      return;
    }
    portalGateToForeground.emit('element-double-clicked', {
      selectionInfo: await getSelectionInfoFromElements({ elements: [element] }),
    });
  };

  isInitialized = true;

  initializeObservers({ containerNode });
};

export async function setHighlightResizeState(value: HighlightResizeState) {
  if (isEqual(value, highlightResizeState)) {
    return;
  }

  const oldValue = cloneDeep(highlightResizeState);
  highlightResizeState = value;

  getRendererOrThrow().containerNode.dataset.highlightResizeStatus = highlightResizeState.status;

  if (highlightResizeState.status === 'inactive') {
    initialSelectionDetailsOfHighlightBeingResized = null;
    if (oldValue.highlightId) {
      const oldHighlightElements = getHighlightElementsForId(oldValue.highlightId);
      for (const oldHighlightElement of oldHighlightElements) {
        delete oldHighlightElement.dataset.resizeStatus;
      }
    }

    return;
  }

  const highlightElements = getHighlightElementsForId(highlightResizeState.highlightId);
  for (const highlightElement of highlightElements) {
    highlightElement.dataset.resizeStatus = highlightResizeState.status;
  }

  if (highlightResizeState.status === 'native-selection-made-but-user-hasnt-started-resizing-yet') {
    await delay(5); // Needed before creating selection

    // Has it changed?
    if (highlightResizeState.status !== 'native-selection-made-but-user-hasnt-started-resizing-yet') {
      return;
    }

    // Select the highlight elements (query for them again to be safe)
    const highlightElements = getHighlightElementsForId(highlightResizeState.highlightId);
    if (!highlightElements.length) {
      logger.warn(
        'No highlight elements found when highlight resize status changed to native-selection-made-but-user-hasnt-started-resizing-yet status',
      );
      return;
    }
    const range = getRangeFromNodes(highlightElements);
    const selection = rangy.getSelection();
    selection.setSingleRange(range);
    initialSelectionDetailsOfHighlightBeingResized = pick(selection, ['focusNode', 'focusOffset']);
  }

  // TODO: remove comment?
  // The status must be actively-resizing from now on...
}

export const setHighlightIdToScrollTo = async ({
  containerNodeSelector,
  docId: docIdArgument,
  id,
}: {
  containerNodeSelector: string;
  docId: typeof docId;
  id: Highlight['id'] | null;
}) => {
  let knownHighlight: KnownHighlight | undefined;
  if (id) {
    knownHighlight = knownHighlights[id];
  }

  const containerNode = containerNodeSelector
    ? document.querySelector<HTMLElement>(containerNodeSelector)
    : document.body;
  hasScrolledToHighlight = false;
  highlightIdToScrollTo = id;

  if (highlightIdToScrollTo) {
    if (!docIdArgument) {
      throw new Error('No docId argument');
    }
    addHighlightIdsToDocIdToIdsOfHighlightThatMustExistMap(docIdArgument, [highlightIdToScrollTo]);

    if (!containerNode) {
      throw new Error("Can't find containerNode");
    }

    if (knownHighlight?.status === KnownHighlightStatus.Rendered) {
      // Try to scroll to it right now
      processHighlights({ contentContainer: containerNode });
    } else if (isInitialized) {
      // This will cause a scroll once it's done
      await renderHighlight({ id: highlightIdToScrollTo });
    } // Otherwise it'll render and scroll once we're initialized
  }
};

export const updateIcons = async (
  highlightId: Highlight['id'],
  details: {
    note: boolean;
    tag: boolean;
  },
): Promise<void> => {
  const detailsEntries = Object.entries(details);
  let didUpdate = false;
  for (const element of getHighlightElementsForId(highlightId)) {
    for (const [iconName, shouldExist] of detailsEntries) {
      const shouldActuallyExist = iconName === 'tag' && isDocumentShareApp ? false : shouldExist;
      const className = `${Renderer.highlightClassName}--has-${iconName}`;
      // We wouldn't need to check this, except that we want to call something afterwards if there was an update
      const doesExist = element.classList.contains(className);
      if (doesExist !== shouldActuallyExist) {
        classListSafe[shouldActuallyExist ? 'add' : 'remove'](element, className);
        didUpdate = true;
      }
    }
  }

  if (didUpdate) {
    portalGateToForeground.emit('content-moved');
  }
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export async function scrollToAnchor({ url, anchorText }: AnchorScrollTarget) {
  if (!renderer) {
    logger.warn('Cannot scroll to document link position, document is not open in Reader');
    return;
  }
  const targetUrl = normalizeUrl(url);
  const anchors = Array.from(getRendererOrThrow().containerNode.getElementsByTagName('a'));
  // Find the best matching anchor based on anchorText and the longest URL prefix.
  const targetAnchor = anchors.reduce(
    (bestMatchAnchor, anchor) => {
      if (anchor.innerText.trim().indexOf(anchorText.trim()) === -1) {
        return bestMatchAnchor;
      }

      const anchorUrl = normalizeUrl(anchor.href);
      if (anchorUrl.indexOf(targetUrl) === -1) {
        return bestMatchAnchor;
      }

      // This anchor's URL has a shorter prefix than the current best match, so skip it.
      // We use longest prefix here to avoid perfectly replicating the omission of query params in Python normalize_url().
      if (bestMatchAnchor !== undefined && anchorUrl.length < bestMatchAnchor.href.length) {
        return bestMatchAnchor;
      }

      // Don't bother with prefix matches that are shorter than 20 chars.
      if (anchorUrl.length < 20) {
        return bestMatchAnchor;
      }

      return anchor;
    },
    undefined as HTMLAnchorElement | undefined,
  );
  if (targetAnchor === undefined) {
    const anchorHrefs = anchors.map((a) => a.href);
    const anchorHrefsNormalized = anchorHrefs.map((href) => normalizeUrl(href));
    logger.warn('Could not find target anchor to scroll to', {
      anchors,
      anchorHrefs,
      anchorHrefsNormalized,
      url,
      normalizedUrl: targetUrl,
    });
    return;
  }
  targetAnchor.focus({
    preventScroll: true,
  });
  await delay(50);
  foregroundEventEmitter.emit('refocus-content-focus-indicator');
}

// eslint-disable-next-line import/no-cycle
export * from '../mobileContentFramePortalGateMethods';
export * from './tts';
