import userAgentParser from 'ua-parser-js';

import { AppVersion } from '../types';

// navigator does not exist in node-based execution environments
function parseUserAgentDesktop(userAgent: string) {
  const userAgentParts = userAgent.split(' ');
  let systemName = userAgentParts[1];
  if (systemName === 'macos') {
    systemName = 'Mac OS';
  } else if (systemName === 'windows') {
    systemName = 'Windows';
  }

  const systemVersion = userAgentParts[2];
  const appVersion: AppVersion = userAgentParts[0].split('/')[1];

  return {
    name: systemName ?? 'Unknown',
    version: systemVersion ?? 'Unknown',
    appVersion,
  };
}

let userAgentInfo = { name: 'Unknown', version: 'Unknown', appVersion: 'unknown' };

if (typeof navigator !== 'undefined') {
  if (navigator.userAgent.startsWith('ReaderDesktop')) {
    userAgentInfo = parseUserAgentDesktop(navigator.userAgent);
  } else {
    const parsedUserAgent = userAgentParser(navigator.userAgent) ?? {
      os: {
        name: 'Unknown',
        version: 'Unknown',
        appVersion: 'unknown',
      },
    };
    const osInfo = parsedUserAgent.os;
    userAgentInfo.name = osInfo.name?.toString() ?? 'Unknown';
    userAgentInfo.version = osInfo.version?.toString() ?? 'Unknown';
  }
}

export const systemName = userAgentInfo.name;
export const systemVersion = userAgentInfo.version;
export const appVersion: AppVersion = userAgentInfo.appVersion;

export const logLevelPreference = process.env.READER_LOG_LEVEL;

export const pdftronAssetsDirectoryName = process.env.VITE_PDFTRON_ASSETS_DIRECTORY_NAME;

export const isRxDBKeyCompressionDisabled = Boolean(process.env.IS_RXDB_KEY_COMPRESSION_DISABLED);
// Force the in-memory RxDB storage to run locally i.e. not in a Shared Worker
// NOTE: Since all queries are run in the UI thread, an excessive DB load might freeze up the app.
// DO NOT EXTRAPOLATE and assume this is the case in production as well. In production, the DB runs in a separate thread.
export const shouldRunRxDBInMemoryStorageLocally = Boolean(
  process.env.SHOULD_RUN_RXDB_IN_MEMORY_STORAGE_LOCALLY,
);

export const shouldPrintSqlQueryPlans = false; // N/A for web
